(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.blogFunctions = {
        ajax: function(){
            var ajax = $('.j-blog');
            if(ajax.length > 0 ){

                ajax.on('change','.j-blog--filter', function(e){
                    e.preventDefault();
                    window.blogFunctions.updateAjax( $(this).parents('.j-blog'), ajax.find('.j-blog--filter').val() );
                });
                ajax.on('submit','.j-blog--filters', function(e){
                    e.preventDefault();

                    var _this = $(this);
                    var searchValue = $('.j-blog--search').val();

					console.log(searchValue);
					console.log(searchValue.length);


					if(searchValue.length > 0){
						window.blogFunctions.updateAjax( $(this).parents('.j-blog'), _this.data('url') + '?' + ajax.find('.j-blog--filters').serialize() );
						$('.j-blog--search').attr('value', searchValue.trim() );
					}
                });
                ajax.on('click','.j-blog--load-more', function(e){
                    e.preventDefault();
                    var block = $(this).parents('.j-blog');
                    var url = $(this).attr('href');
                    window.blogFunctions.fetch( url, function(html){

                        var results = $(html);
                        var searchValue = block.find('.j-blog--search').val();

                        block.find('.j-blog--filters').html( results.find('.j-blog--filters').html() );
                        block.find('.j-blog--search').attr('value', searchValue );

                        var newResults = results.find('.j-blog--posts');

                        newResults.find('.o-index--post').addClass('s-hidden');

                        block.find('.j-blog--posts').append( newResults.html() );
                        block.find('.j-blog--pagination').html( results.find('.j-blog--pagination').html() );

                    });
                });
            }
        },
        updateAjax: function( block, url){

            window.blogFunctions.fetch( url, function(html){
                var results = $(html);


                block.find('.j-blog--header').html( results.find('.j-blog--header').html() );
                block.find('.j-blog--posts').html( results.find('.j-blog--posts').html() );
                block.find('.j-blog--pagination').html( results.find('.j-blog--pagination').html() );

                if(block.data('update-url')){
                    window.blogFunctions.updateURL(url, results.filter('title').text() );
                }

                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: $('.j-blog').offset().top - 50
                }, 1000);



                return false; // Exit.
            });
        },
        updateURL: function(url, title){
            document.title = title;
            history.pushState({}, title, url);
        },
         // Fetch a page
        fetch: function( url, callback){
            var container = $('body');
            if(! container.hasClass('j-ajax__loading')){
                // Non ajax page reload
                container.addClass('j-ajax__loading');
                container.trigger('ajaxLoadBegin');
                // ajax request a page
                this.ajax = jQuery.ajax({
                    dataType: 'html',
                    url: url,
                    success: function (rawhtml) {
                        // get results
                        container.removeClass('j-ajax__loading');
                        container.trigger('ajaxLoadComplete');
                        // run callback function
                        callback( rawhtml );
                    },
                    error: function(){
                        container.trigger('ajaxLoadFail');
                    },
                });
            }
        },
        filterToggle: function(){
            $('.m-blog-filters--title').on('click',function(){
                if($('.m-blog-filters--title-icon').is(":visible")){
                    $(this).toggleClass('is_active');
                    $('.m-blog-filters--filters').slideToggle();
                }
            });
        }

    };

    function parseURISearchParams(uri, x, y) {
        x = x || function(x) {
            x = decodeURIComponent(x && x.replace(/\+/g, ' '));
            return (!isNaN(parseInt(x)) && !isNaN(x * 1)) ? x * 1 : x;
        };
        y = y || decodeURIComponent;

        var o = {};

        if (uri === '') {
            return o;
        }

        function add(o, k, v) {
            if (o.hasOwnProperty(k)) {
                if (o[k] instanceof Array) {
                    o[k].push(v);
                } else if (!(o[k] instanceof Object)) {
                    o[k] = [o[k], v];
                }
            } else {
                o[k] = v;
            }
            o = o[k];
            return o;
        }

        return uri.match(/([^=&]+)(=([^&]*))?/g).reduce(function(c, p, i, d) {
            i = /([^=]+)(=(.*))?/.exec(p);
            var k = y(i[1]),
                v = i[3] || '';

            if (/\[/.test(k)) {
                k.match(/([^\[]+)|\[([^\]]+)\]/g).reduce(function(c, p, i, d) {
                    return add(c, p.replace(/[\[\]]/g, ''), (d.length - 1 === i ? x(v) : {}));
                }, o);
            } else {
                add(c, k, x(v));
            }
            return o;
        }, o);
    }
}(window.jQuery, window, document)); // End of jQuery wrapper

