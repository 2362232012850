/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				AOS.init({
					once: true,
					offset: 100,
					duration: 800,
				});


				// JavaScript to be fired on all pages
				window.HeaderFunctions.setup();
				window.blockFunctions.setup();

				window.blogFunctions.ajax();


				$('.j-popup--open').on('click',function(e){
					e.preventDefault();
					var popup = $(this).data('popup');
					$('.o-popup').removeClass('is_active');
					var container = $('.o-popup[data-popup="' + popup + '"]').addClass('is_active');

					var form = container.find('.gform_wrapper').clone();
					form.appendTo($('.o-form-holder'));
				});
				$('.j-popup--close').on('click',function(e){

					var form = $('.o-form-holder').find('.gform_wrapper').detach();

					var active = $('.o-popup.is_active');

					active.find('.gform_confirmation_wrapper').after(form);
					active.find('.gform_confirmation_wrapper').detach();
					$('.o-popup').find('.gform_ajax_spinner').detach();

					$('.o-popup').removeClass('is_active');
				});
				$('.o-popup--inner').on('click',function(e){
					e.stopPropagation();
				});

				$('.j-newsletter--scroll').on('click',function(){
					$('#footer-newsletter').find('input[type="text"]').focus();
				});

				var buttons = Array();
				$('.s-magnetic-button').each(function(){
					buttons.push(new window.magneticButtons( this ));
				});




			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired


			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
