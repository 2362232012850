(function ($, window, document) {
	// jQuery wrapper

	// Page Functions
	window.blockFunctions = {
		setup: function () {
			this.youTubeAPI();
			this.banner();
			this.list();
			this.cards();
			this.reports();
			this.accordion();
			this.video();
		},
		adminSetup: function () {
			this.banner();
			this.list();
			this.accordion();
		},
		youTubeAPI: function () {
			// if($('.j-youtube').length > 0){
			//     var tag = document.createElement('script');
			//     tag.src = "https://www.youtube.com/iframe_api";
			//     var firstScriptTag = document.getElementsByTagName('script')[0];
			//     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			// }
		},
		banner: function () {
			$(".j-banner--play").on("click", function () {
				$("body").addClass("banner-video-playing");
				var block = $(this)
					.parents(".o-banner")
					.addClass("banner-video-playing");

				if (block.next().find("video").length > 0) {
					block.next().find("video").get(0).play();
				} else {
					block.next().find("iframe")[0].src += "&autoplay=1";
				}
			});
			$(".o-banner--video-close").on("click", function () {
				$("body").removeClass("banner-video-playing");
				var block = $(this).parents(".o-banner--video");
				block.prev().removeClass("banner-video-playing");

				var iframe = block.find("iframe")[0];
				var video = block.find("video")[0];
				if (iframe) {
					var iframeSrc = iframe.src.replace("&autoplay=1", "");
					console.log(iframeSrc);
					iframe.src = iframeSrc;
				}
				if (video) {
					video.pause();
				}
			});
		},
		video: function () {
			$(".j-content-video--play").on("click", function () {
				$("body").addClass("j-content-video-video-playing");
				var block = $(this)
					.parents(".j-content-video")
					.addClass("j-content-video__playing");

				if (
					block.find(".gtag-content-media--video").find("video")
						.length > 0
				) {
					block
						.find(".gtag-content-media--video")
						.find("video")
						.get(0)
						.play();
				} else {
					block
						.find(".gtag-content-media--video")
						.find("iframe")[0].src += "&autoplay=1";
				}
			});
			$(".j-content-video--close").on("click", function () {
				$("body").removeClass("j-content-video-video-playing");
				var block = $(this).parents(".j-content-video");
				block.removeClass("j-content-video__playing");

				var iframe = block
					.find(".gtag-content-media--video")
					.find("iframe")[0];
				var video = block
					.find(".gtag-content-media--video")
					.find("video")[0];
				if (iframe) {
					var iframeSrc = iframe.src.replace("&autoplay=1", "");
					iframe.src = iframeSrc;
				}
				if (video) {
					video.pause();
				}
			});
		},
		list: function () {
			$(".j-list").on("click", ".j-list--heading", function () {
				$(this)
					.toggleClass("active")
					.parents(".j-list--item")
					.find(".j-list--body")
					.slideToggle();
			});
		},
		accordion: function () {
			$(".j-accordion").on("click", ".j-accordion--heading", function () {
				if ($(this).parents(".j-accordion--item").hasClass("active")) {
				} else {
					$(this)
						.parents(".j-accordion--item")
						.toggleClass("active")
						.siblings()
						.removeClass("active")
						.find(".j-accordion--body")
						.slideUp();
					$(this)
						.parents(".j-accordion--item")
						.find(".j-accordion--body")
						.slideToggle();
				}
			});
		},
		cards: function () {
			$(".j-card").on("click", ".j-card--toggle", function () {
				$(this)
					.parents(".j-card")
					.toggleClass("extra-text-open")
					.find(".j-card--extra")
					.slideToggle();
			});
			if ($(".j-card--carousel").length > 0) {
				$(".j-card--carousel").slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "60px",
					dots: true,
				});
			}
		},
		reports: function () {
			$(".j-reports").on("click", ".j-reports--item", function () {
				if ($(this).hasClass("active")) {
					// already set
				} else {
					var report = $(this).data("report");
					$(".j-reports--content.is_active").removeClass("is_active");
					$(
						'.j-reports--content[data-report="' + report + '"]'
					).addClass("is_active");
					$(this)
						.addClass("is_active")
						.siblings()
						.removeClass("is_active");
				}
			});
			if ($(".j-reports--carousel").length > 0) {
				$(".j-reports--carousel").slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: true,
					dots: true,
				});
			}
		},
	};
})(window.jQuery, window, document); // End of jQuery wrapper
