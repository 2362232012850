(function ($, window, document) {
	// jQuery wrapper

	// Page Functions
	window.magneticButtons = class {
		constructor(el) {
			// DOM elements
			// el: main button
			// text: inner text element
			this.DOM = { el: el };
			this.DOM.text = this.DOM.el.querySelector(
				".s-magnetic-button--text"
			);
			this.DOM.textinner = this.DOM.el.querySelector(
				".s-magnetic-button--text__inner"
			);
			this.DOM.deco = this.DOM.el.querySelector(
				".s-magnetic-button__deco"
			);
			this.DOM.filler = this.DOM.deco.querySelector(
				".s-magnetic-button__deco-filler"
			);
			// amounts the button will translate/scale
			this.renderedStyles = {
				tx: { previous: 0, current: 0, amt: 0.1 },
				ty: { previous: 0, current: 0, amt: 0.1 },
				scale: { previous: 1, current: 1, amt: 0.2 },
			};

			// button state (hover)
			this.state = {
				hover: false,
			};

			this.winsize = 0;
			this.mousepos = { x: 0, y: 0 };

			(this.winsize = this.calcWinsize()),
				window.addEventListener(
					"resize",
					() => (this.winsize = this.calcWinsize())
				);
			window.addEventListener(
				"mousemove",
				(ev) => (this.mousepos = this.getMousePos(ev))
			);

			// window.addEventListener('resize', this.calculateSizePosition() );
			window.addEventListener("resize", () =>
				this.calculateSizePosition()
			);

			// calculate size/position
			this.calculateSizePosition();

			var _this = this;

			$(".height-change-listener").each(function () {
				$(this.contentWindow).resize(function () {
					_this.calculateSizePosition();
					setTimeout(() => _this.calculateSizePosition(), 1000);
				});
			});

			setTimeout(() => this.calculateSizePosition(), 1000);

			requestAnimationFrame(() => this.render());
		}

		calcWinsize() {
			return { width: window.innerWidth, height: window.innerHeight };
		}

		// Gets the mouse position
		getMousePos(e) {
			var posx = 0;

			var posy = 0;
			if (!e) {
				e = window.even;
			}
			if (e.pageX || e.pageY) {
				posx = e.pageX;
				posy = e.pageY;
			} else if (e.clientX || e.clientY) {
				posx =
					e.clientX +
					body.scrollLeft +
					document.documentElement.scrollLeft;
				posy =
					e.clientY +
					body.scrollTop +
					document.documentElement.scrollTop;
			}
			return { x: posx, y: posy };
		}

		calculateSizePosition() {
			// size/position
			this.rectBox = this.DOM.el.getBoundingClientRect();

			this.rect = {
				left: this.rectBox.left,
				right: this.rectBox.right,
				bottom: this.rectBox.bottom + window.scrollY,
				top: this.rectBox.top + window.scrollY,
				width: this.rectBox.width,
				height: this.rectBox.height,
			};
			// the movement will take place when the distance from the mouse to the center of the button is lower than this value
			// this.distanceToTrigger = this.rect.width*1.5;
			this.distanceToTrigger = this.rect.width * 0.8;
			// return this.rect.width  * 0.8;
		}

		render() {
			// calculate the distance from the mouse to the center of the button
			var distanceMouseButton = this.distance(
				this.mousepos.x,
				this.mousepos.y,
				this.rect.left + this.rect.width / 2,
				this.rect.top + this.rect.height / 2
			);
			// new values for the translations and scale
			var x = 0;
			var y = 0;

			if (distanceMouseButton < this.distanceToTrigger) {
				if (!this.state.hover) {
					this.enter();
				}
				x =
					(this.mousepos.x - (this.rect.left + this.rect.width / 2)) *
					0.3;
				y =
					(this.mousepos.y - (this.rect.top + this.rect.height / 2)) *
					0.3;
			} else if (this.state.hover) {
				this.leave();
			}

			this.renderedStyles["tx"].current = x;
			this.renderedStyles["ty"].current = y;

			for (var key in this.renderedStyles) {
				this.renderedStyles[key].previous = this.lerp(
					this.renderedStyles[key].previous,
					this.renderedStyles[key].current,
					this.renderedStyles[key].amt
				);
			}

			this.DOM.el.style.transform = "translate3d("
				.concat(this.renderedStyles["tx"].previous, "px, ")
				.concat(this.renderedStyles["ty"].previous, "px, 0)");
			this.DOM.text.style.transform = "translate3d("
				.concat(-this.renderedStyles["tx"].previous * 0.2, "px, ")
				.concat(-this.renderedStyles["ty"].previous * 0.2, "px, 0)");
			this.DOM.deco.style.transform = "scale(".concat(
				this.renderedStyles["scale"].previous,
				")"
			);

			requestAnimationFrame(() => this.render());
		}
		enter() {
			// this.emit('enter');
			this.state.hover = true;

			this.DOM.el.classList.add("s-magnetic-button--hover");
			document.body.classList.add("active");

			this.renderedStyles["scale"].current = 1.3;

			gsap.killTweensOf(this.DOM.filler);
			gsap.killTweensOf(this.DOM.textinner);
			gsap.killTweensOf(document.body);

			gsap.timeline()
				.to(
					this.DOM.filler,
					0.5,
					{
						ease: "Power3.easeOut",
						startAt: { y: "75%" },
						y: "0%",
					},
					0
				)
				.to(
					this.DOM.textinner,
					0.4,
					{
						ease: "Expo.easeOut",
						scale: 0.8,
					},
					0
				);
		}

		leave() {
			// this.emit('leave');
			this.state.hover = false;

			this.DOM.el.classList.remove("s-magnetic-button--hover");
			document.body.classList.remove("active");

			this.renderedStyles["scale"].current = 1;

			gsap.killTweensOf(document.body);
			gsap.killTweensOf(this.DOM.filler);

			gsap.timeline()
				.to(
					this.DOM.filler,
					0.4,
					{
						ease: "Power3.easeOut",
						y: "-75%",
					},
					0
				)
				.to(
					this.DOM.textinner,
					0.4,
					{
						ease: "Expo.easeOut",
						scale: 1,
					},
					0
				);
		}

		// Map number x from range [a, b] to [c, d]
		map(x, a, b, c, d) {
			return ((x - a) * (d - c)) / (b - a) + c;
		}

		// Linear interpolation
		lerp(a, b, n) {
			return (1 - n) * a + n * b;
		}

		distance(x1, y1, x2, y2) {
			var a = x1 - x2;
			var b = y1 - y2;

			return Math.hypot(a, b);
		}
	};
})(window.jQuery, window, document); // End of jQuery wrapper
